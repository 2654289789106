<template>
  <div class="grid grid-rows-[auto_1fr_auto] h-[100vh] bg-gray-50 dark:bg-gray-900">
      <main class=" flex-col h-screen overflow-y-auto bg-white dark:bg-gray-700">
        <div class="flex container min-h-screen max-w-full ">
          <slot />
        </div>
      </main>
        <Footer  />
  </div>
</template>
<script setup lang="ts">

</script>
